:root {
    --main-bg-color: #f0f0f0;
    --accent-bg-color: #d3a21d;
    --text-color-dark: #000;
    --text-color-light: #fff;
    --border-radius: 15px;
}

.chat-menu {
    min-height: 100vh;
    max-height: 100%;
    width: 400px; 
    text-align: center;
    background-color: var(--main-bg-color);
    padding: 10px;
}

.chat-menu-btn {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    margin-bottom: 20px;
}
  
.nft-container {
    margin: 0 auto;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 16px;
    margin-bottom: 16px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: var(--accent-bg-color);
    width:250px; 
}

.chat-menu-message{
    max-width: 300px;
    margin-bottom: 10px;
    color: #000;
    font-size: 16px;
    font-weight: 500;
    border-radius: 5px;
    text-align: center;
}
  
.nft-container img {
    width: 100%;
    border-radius: 8px;
    margin-bottom: 12px;
}
  
.nft-name {
    font-size: 18px;
    margin-bottom: 8px;
}
  
.nft-description {
    font-size: 14px;
}

@media (max-width: 900px) {
    .chat-menu {
        width: 100vw;
    }
    .nft-container {
        margin: 0 auto;
        border: 1px solid #ccc;
        border-radius: 8px;
        padding: 16px;
        margin-bottom: 16px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        background-color: var(--accent-bg-color);
        width:350px; 
    }
      
    .nft-container img {
        width: 100%;
        border-radius: 8px;
        margin-bottom: 12px;
    }
    
}

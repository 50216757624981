@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap');
/* Reset and base styles  */
* {
	padding: 0px;
	margin: 0px;
	border: none;
}

*,
*::before,
*::after {
	box-sizing: border-box;
}

body{
    font-family: "Nunito Sans", sans-serif;
}

:focus,
:active {
	/*outline: none;*/
}

a:focus,
a:active {
	/* outline: none;*/
}

/* Links */

a, a:link, a:visited  {
    /* color: inherit; */
    text-decoration: none;
    /* display: inline-block; */
}

a:hover  {
    /* color: inherit; */
    text-decoration: none;
}

/* Common */

aside, nav, footer, header, section, main {
	display: block;
}

h1, h2, h3, h4, h5, h6, p {
    font-size: inherit;
	font-weight: inherit;
}

ul, ul li {
	list-style: none;
}

img {
	vertical-align: top;
}

img, svg {
	max-width: 100%;
	height: auto;
}

address {
  font-style: normal;
}

/* Form */

input, textarea, button, select {
	font-family: inherit;
    font-size: inherit;
    color: inherit;
    background-color: transparent;
}

input::-ms-clear {
	display: none;
}

button, input[type="submit"] {
    display: inline-block;
    box-shadow: none;
    background-color: transparent;
    background: none;
    cursor: pointer;
}

input:focus, input:active,
button:focus, button:active {
    outline: none;
}

button::-moz-focus-inner {
	padding: 0;
	border: 0;
}

label {
	cursor: pointer;
}

legend {
	display: block;
}



.App {
	display: flex;
	justify-content: space-between;
  }
  
.menu-btn {
	max-width: 30px;
	max-height: 30px;
	margin: auto 0;
	transform: rotate(90deg);
	transition: transform 0.5s ease;
}

.menu-btn img {
	animation: pulse 2s infinite;
}

.menu-btn.top {
	order: -1;
}

.menu-btn.rotate {
	transform: rotate(270deg);
}

@keyframes pulse {
	0% {
	  transform: scale(1);
	}
	50% {
	  transform: scale(1.1);
	}
	100% {
	  transform: scale(1);
	}
  }
  
@media (max-width: 900px) {
	.App {
	  flex-direction: column;
	}
 
	.menu-btn {
	  margin: 10px auto;
	  transform: rotate(180deg);
	}

	.menu-btn.rotate {
		transform: rotate(0deg);
	}
}
  
  
